import Vue from 'vue'
import App from './App.vue'
import store from '@/store'
import router from '@/router'
import Axios from 'axios'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import VueCompositionAPI from '@vue/composition-api'


import HumanTime from '@/services/filters/human.time'
import HumanDate from '@/services/filters/human.date'

// Инициализация плагина маски на ввод
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

// Инициализация плагина размера экрана
import VueMq from 'vue-mq'
Vue.use(VueMq, {
    breakpoints: {
      sm: 800,
      md: 1200,
      lg: Infinity,
    }
})

import socket from './services/socket';

import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/css/bootstrap-reset.css'
import '@/assets/css/main.css'

import peer from 'peerjs';
Vue.prototype.$peer = peer;

// Инициализация шины событий
Vue.prototype.$eventBus = new Vue();

// Плагин iframe для карты проезда
import VueFriendlyIframe from 'vue-friendly-iframe';
Vue.use(VueFriendlyIframe);



Vue.use(VueCompositionAPI)

// Инициализация moment.js
import moment from 'moment';
import 'moment/locale/ru'
Vue.prototype.$moment = moment;
moment.locale('ru');

// Инициализация слайдера
import VueCarousel from '@chenfengyuan/vue-carousel';
Vue.component(VueCarousel.name, VueCarousel);

import VOtpInput from 'vuetify/lib'
Vue.use(VOtpInput);
// Фильтры для дат, перевод во время и в человеческую дату 
Vue.filter('humanTime', HumanDate)
Vue.filter('humanDate', HumanTime)

// Плагин валидации
Vue.use(Vuelidate)

// Сохранение исходного url
localStorage.setItem('primaryPath', window.location.pathname);

// Инициализация axios
Vue.prototype.$http = Axios;
const token = localStorage.getItem('token');
// Если токен установлен
if (token){
  // Добавляем заголовки в запросы
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}
Axios.interceptors.response.use(
  response => {
    // Установлен ли таймер на отображение об ошибке сети
    if (store.state.requestTimer) {
      // отменяем действие таймера
      clearTimeout(store.state.requestTimer)
      store.state.requestTimer = null
      clearInterval(store.state.requestInterval)
      store.state.requestInterval = null
      // скрываем оповещение
      store.commit('toggleErrorSnackbar', false)
      window.location.reload()
    }
    return response
  },
  error => {
    // Если ошибка сети
    if (error.message === "Network Error") {
      // Если таймер на отображение предупреждения еще не установлен
      if (!store.state.requestTimer) {
        store.state.requestTimer = setTimeout(() => {
          store.commit('toggleErrorSnackbar', true)
        }, 10000)
      }
      if (!store.state.requestInterval) {
        store.state.requestInterval = setInterval(() => {
          return Axios.request(error.config)
        }, 3000)
      }
      return
    }
    // Выход из системы в случае ошибки авторизации
    console.log(error.response)
    if (error.response?.status === 401 || error.response?.status === 422) {
      store.dispatch('logout')
      .then(() => {
          const url = new URL(window.location.href);
          let additional = '';
          if (url.searchParams.get('refer') === 'site') {
            additional = '&refer=site';
          }
          router.push(`/login?main=75${additional}`);
      })
    }
    if (error.response?.status === 503) {
      store.dispatch('logout')
      .then(() => {
        router.push('/not-available')
      })
    }
  }
);

Vue.prototype.$peer = null;
Vue.prototype.$call = null;
Vue.prototype.$isMobile = false;


// Проверка каждого route
router.beforeEach((to, from, next) => {

  
  // если нужна авторизация через магазин 
  if (to.query.authService) {
    localStorage.setItem('authService',to.query.authService)
    localStorage.setItem('authServiceUrl',to.query.url)
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = store.getters.token || localStorage.getItem('token')
    store.dispatch('checkSession', token)
      .then(next)
    if (localStorage.getItem('token')) {
      if (localStorage.getItem('authService') && localStorage.getItem('authService') == 'shop') {
        next({name: 'shopAuth'})
      }
      else
      {
        next()
      }
      return
    }
    const url = new URL(window.location.href);
    let additional = '';
    if (url.searchParams.get('refer') === 'site') {
      additional = '?refer=site';
    }
    next(`/login${additional}`);
  } else {
    next() 
  }
})

// Проверяем get параметры если пришли по ссылке для создания записи
let url = new URL(window.location.href);
let dateParam = url.searchParams.get('date');
let idUserParam = url.searchParams.get('idUser');
if (url.searchParams.has('source') && !localStorage.getItem('customerSource')) {
  localStorage.setItem('customerSource', url.searchParams.get('source'));
}
let services = url.searchParams.has('services') ? url.searchParams.get('services') : null;

url.searchParams.delete('services');
url.searchParams.delete('source');
url.searchParams.delete('date');
url.searchParams.delete('idUser');
window.history.replaceState({}, document.title, url);

// Если переданы параметры создания записи по ссылке
if (dateParam && idUserParam){
  localStorage.setItem('newAppointmentDate', JSON.stringify(dateParam));
  localStorage.setItem('newAppointmentIdUser', JSON.stringify(idUserParam));
  localStorage.setItem('newAppointmentIdService', JSON.stringify(services));
}

var deviceReadyInterval;

// Если мобильное устройство ждем готовности устройства
if (window.indexPlatform == 'android' || window.indexPlatform == 'ios'){  
  clearInterval(deviceReadyInterval)
  Vue.prototype.$isMobile = true;
  deviceReadyInterval = setInterval(function(){
    if (window.isDeviceReady){
      // Инициализация проекта
      new Vue({
        store,
        router,
        vuetify,
        render: h => h(App)
      }).$mount('#app')
      clearInterval(deviceReadyInterval);
    }
  }, 500)
} else {
  // Инициализация проекта
  new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}
