<template>
  <div
      class="d-flex"
      v-if="whole != '0' && !hidePrice"
  >
    <span class="font-weight-medium"> {{ approximately ? 'от ' + getWhole : getWhole }} </span>
    <small
        class="font-weight-medium "
        v-if="remains != '00'"
    >
      <sup>{{ remains }}</sup>
    </small>
    <span class="font-weight-medium">₽</span>
  </div>
  <div v-else>
    <span class="font-weight-medium">{{ textOnZero }}</span>
  </div>
</template>

<script>

export default {
  name: 'PriceTotal',

  props: {
    price: Number,
    count: {
      default: 1,
      type: Number
    },
    approximately: {
      default: false,
      type: Boolean
    },
    hidePrice: {
      default: false,
      type: Boolean
    },
    textOnZero: {
      type: String,
      default: 'Нет цены'
    }
  },

  computed: {
    priceWithCount() {
      return this.price * this.count
    },

    whole() {
      return Math.trunc(this.priceWithCount / 100) || 0
    },

    remains() {
      const value = Math.trunc(this.priceWithCount % 100) || 0
      return (value < 10 ? '0' + value : value).toString()
    },
    
    getWhole() {
      return new Intl.NumberFormat('ru-RU').format(this.whole)
    }
  }


}
</script>