<template>
    <v-snackbar
       v-model="showDialog"
        vertical
        timeout="30000"
        open-delay="2000"
        outlined
        color="grey lighten-2"
        elevation="5"
    >
      <v-card class="mx-none px-none" flat max-width="344" >
        <v-list-item three-line>
          <v-list-item-content>
            <div class="text-overline mb-0">
              {{ offerOs === 'ios' ? 'App Store' : ' Google Play'}}
            </div>
            <v-list-item-title class="text-h5 mb-3">
              Доктор Знает
            </v-list-item-title>
            <v-list-item-subtitle>Получайте все услуги, узнавайте о скидках в нашем приложении</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-avatar
              tile
              size="60"
          >
            <v-img
                v-if="offerOs === 'ios'"
                src="@/assets/images/app-store.png"
                transition="false"
            />
            <v-img
                v-if="offerOs === 'android'"
                src="@/assets/images/google-play.png"
                transition="false"
            />
          </v-list-item-avatar>
        </v-list-item>

        <v-card-actions>
          <div class="w-100">


          <v-btn
              outlined
              rounded

              color="primary"
              class="mb-2"
              @click="toStore()"
              block
          >
            Установить
          </v-btn>
          <v-btn
              outlined
              rounded
              text
              color="light"
              @click="notNow"
              block
          >
            Не сейчас
          </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-snackbar>
</template>

<script >

import {mapGetters} from "vuex";

export default {
  name: 'AppOfferDialog',
  data: function () {
    return {
      showDialog: false,
      offerOs: '',
      offerUrl: ''
    }
  },

  watch: {
    value(newValue) {
      this.showCreateClientDialog = newValue;
    },
  },

  methods: {

    toStore(){
      this.showDialog = false;
      window.open(this.offerUrl, '_blank');
    },

    notNow() {
      window.localStorage.setItem('appOfferSkip', true);
      this.showDialog = false;
    },
    getOs() {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;
        if (macosPlatforms.indexOf(platform) !== -1) {
          os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
          os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
          os = 'Windows';
        } else if (/Android/.test(userAgent)) {
          os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
          os = 'Linux';
        }
        return os;
    },
  },

  mounted() {
    window.localStorage.setItem('appOfferSkip', '')
    setTimeout(( ) => {

      let os = this.getOs();

      // Если определили ОС и заходим с браузера
      if (os && window.indexPlatform == 'web' && !window.localStorage.getItem('appOfferSkip') ){

        if (os == 'Android'){
         // this.showDialog = true;
          this.offerOs = 'android';
          this.offerUrl = this.STATIC.appGooglePlayUrl;
        }
        if (os == 'iOS'){
        //  this.showDialog = true;
          this.offerOs = 'ios';
          this.offerUrl = this.STATIC.appAppStoreUrl;
        }
      }
    }, 2000)
  },

  computed: {
    ...mapGetters([
      'STATIC'
    ]),
  },
}



</script>