<template>
  <v-container>
    <v-row>
        <v-col
          md="10"
          offset-md="1"
          sm="12"
          xs="12"
        >
          <div v-if="jsonSiteServiceButton" id="example-1" class="word_preset__list row">
<!--            <template v-for="(button, index) in JSON.parse(jsonSiteServiceButton)">
              <v-btn
                v-if="button.text && button.text.trim()"
                depressed
                :key="index"
                :color="button.color"
                class="ma-2 white&#45;&#45;text"
                outlined
                @click="clickPreset(button.text)"
              >{{button.text}}</v-btn>
            </template>-->
            
            <v-card class="w-100 pa-0 mb-4" flat outlined>
              <v-list class="w-100 pa-0" nav>
                <v-list-group v-model="isFilterOpen" no-action>
                  <template v-slot:activator>
                    <div class="w-100 text-start pl-2">
                      <v-icon left small color="grey darken-1">fas fa-filter</v-icon>Фильтр
                    </div>
                  </template>
                  
                  <div v-for="(button, index) in JSON.parse(jsonSiteServiceButton)" :key="index">
                    
                    <v-list-item v-if="button.text && button.text.trim()" :selectable="true" @click="clickPreset(button.text);isFilterOpen = false" style="padding: 0; margin:0">
                      <v-list-item-content class="w-100 pl-4" style="padding: 0">
                        {{button.text}}
                      </v-list-item-content>
                    </v-list-item>
                    
                  </div>
                  
                </v-list-group>
              </v-list>
            </v-card>
          </div>
          <v-text-field  
            ref="inputTextSearch"
            @input="inputChangeHandler" 
            @click:clear="clearHandler"
            label="Введите название услуги" 
            v-model="input" 
            clearable
          ></v-text-field>
          <div class="loader" v-if="loading">
              <v-skeleton-loader
                type="list-item-two-line"
                v-for="i in 5"
                :key="i"
              ></v-skeleton-loader>
          </div>
          
          <div class="list" v-if="services && !loading">
            <Tabs :sortServices="sortServices" :q="q"/>
          </div>
          <p class="label" v-if="services && !services.length && q && !loading">
            <i class="fas fa-exclamation-circle"></i> 
            Не найдено подходящих услуг
          </p>

        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

import Tabs from './Tabs.vue'

export default {
  created(){
    if (this.q) {
      this.input = this.q
    }
    this.getData(this.q)
  },
  data(){
    return {
      loading: false,
      services: [],
      input: this.$route.query.input,
      timerId: null,
      isFilterOpen: false
    }
  },
  props: {
    // функция отображения списка врачей для текущей даты
    usersShowHandler: Function,
    // функция изменения фильтра поиска врачей по специальности
    setIdSpecial: Function,
  },
  components: {
    Tabs,
  },
  computed: {
    ...mapGetters(['service', 'q', 'STATIC', 'jsonSiteServiceButton']),
    sortServices(){
      const res = {}
      this.services.forEach(e => {
        let name = e.name
        if (name) {
          if (name[0] === '"') name = name.slice(1)
          name = name.trim()
          if (name && name[0].match(/[а-я]/i)){
            res[name.trim()[0].toLowerCase()] = []
          }
          else {
            res['*'] = []
          }
        }
      })
      this.services.forEach(e => {
        let name = e.name
        if (name) {
          if (name[0] === '"') {
            name = name.slice(1)
          }
          name = name.trim()
          if (name && name[0].match(/[а-я]/i)){
            res[name.trim()[0].toLowerCase()].push(e)
          }
          else {
            res['*'].push(e)
          }
        }
      })
      const sortRes = {}
      Object.keys(res).sort().forEach(key => {
        sortRes[key] = res[key]
      })
      return sortRes
    }
  },
  methods: {
    clickPreset(value){
      this.input = value;
    },
    clearHandler(){
      this.$store.commit('setService', null)
      localStorage.service = null
      this.getData('')
    },
    // обработчик изменения ввода инпута
    inputChangeHandler(e){
      const data = e ? e : ''
      this.$store.commit('setQ', data)
      if (!e) return
      // функция задержки обработки ввода
      this.inputDebounce(e.trim())
    },
    // функция задержки обработки ввода
    inputDebounce(e){
      // отчиска предыдущего таймера
      clearTimeout(this.timerId)
      // если данные из инпута удалены
      if (!e) {
        this.timerId = setTimeout(() => {
          this.users = ''
          this.specials = ''
          this.loading = false
          this.getData('')
        }, 1000)
        return
      }
      // устанавливаем новый таймер на обработку введенных данных
      this.timerId = setTimeout(() => {
        this.loading = true 
        this.getData(e)
      }, 1000)
    },
    // получение объекта с пользователями и специальностями
    getData(q){
      this.loading = true
      this.$store.dispatch('getServices', encodeURIComponent(q))
      .then(res => {
        this.services = res.data.services
        this.loading = false
      })
      .catch(err => {
        console.log(err)
        this.loading = false
      })
      .finally(() => this.loading = false)
    },
  },
  watch: {
    input: function(e) {
      this.inputChangeHandler(e);
    }
  },
}
</script>

<style scoped>
  .list{
    display: flex;
    flex-wrap: wrap;
  }
  .label{
    color: rgb(124, 124, 124);
  }
  .list-item{
    cursor: pointer;
    padding: 10px;
    display: flex;
    min-width: 50%;
    margin-bottom: 10px;
  }
  .list-item img{
    width: 70px;
    height: 70px;
    border-radius: 90%;
  }
  .list-item .special{
    padding: 10px;
    /* border-left: 1px solid #ccc; */
  }
  .list-item .special:hover{
    color: var(--primary-color);
  }
  .user-info{
    padding: 10px;
  }
  .user-special{
    color: #ccc;
  }
</style>

<style>
  .v-list-group__header.v-list-item {
    margin-bottom: 0 !important;
  }

</style>