<template>
  <div>
    <ProgressBar :progress="2"/>
    <div class="choice-title">
      <h5>Куда хотите записаться?</h5>
    </div>
    <v-card
      elevation="2"
      class="mx-auto client-choice"
      max-width="400"
      outlined
      @click="clickUsersHandler"
    >
        <span>Выбрать врача</span>
    </v-card>
    <v-card
      elevation="2"
      class="mx-auto client-choice"
      max-width="400"
      outlined
      @click="clickServicesHandler"
    >
        <span>Выбрать услугу</span>
    </v-card>
  </div>
</template>

<script>
import router from '../../router'

import ProgressBar from '../../components/appointment/ProgressBar.vue'
import { mapGetters } from 'vuex'

export default {
  name: "DirectionSelect",
  components: {
    ProgressBar,
  },
  created(){
    // перенаправление если не выбран возраст клиента
    if (!this.age) router.push('/appointment-new')
  },
  methods: {
    // обработка выбора направления (врачи)
    clickUsersHandler(){
      // переадресация на список врачей
      router.push({
        path: '/users',
        query: {
          idCustomer: this.$route.query.idCustomer
        }
      });
    },
    // обработка выбора направления (услуги)
    clickServicesHandler(){
      // переадресация на список услуг
      router.push({
        path: '/services',
          query: {
          idCustomer: this.$route.query.idCustomer
        }
      });
    },
  },
  computed: mapGetters(['age'])

}
</script>

<style scoped>
  .client-choice{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin: 0.5rem;
  
    cursor: pointer;
  }
  .card-avatar{
    width: 20%;
    margin-right: 0.5rem;
  }
  .choice-title{
    text-align: center;
    margin: 1rem 0;
  }
</style>