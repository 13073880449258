import axios from 'axios'

export default {
    getAdvertising({commit} ){
        return axios({
            url: window.config.apiUrl + '/advertising/get',
            method: 'GET',
        })
            .then(res => {
                commit('setAdvertising', res.data);
                commit('showModal');
                if(!res.data.advertising) {
                    localStorage.removeItem('dateTimesAdvertising');
                }
                return res
            })
            .catch(err => {console.log(err)})
    },
}