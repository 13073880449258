<template>
  <v-app class="v-app-section">
    <router-view />
    <NetworkErrorSnackbar />
    <Advertising v-if="GET_ADVERTISING" :advertising="GET_ADVERTISING"></Advertising>
<!--    <AppVersionDialog/>-->
    <AppOfferDialog/>

    <v-dialog
        v-model="notifyDialog"
        width="350"
    >
      <v-card class="text-center">
        <v-card-title class="text-h5">
          Уведомления
        </v-card-title>

        <img src="@/assets/images/notification.png" alt="" style="width: 180px; margin: auto; margin-bottom: 20px">
        <v-card-text>Включите push-уведомления, чтобы мы могли вовремя напоминать вам о ваших визитов в клинику</v-card-text>

        <v-card-actions>
          <div class="w-100">
            <v-btn color="primary" depressed block class="mb-2" @click="showPushAlert()">
              Разрешить
            </v-btn>
            <v-btn depressed text block class="mb-2" @click="disallowPush()">
              Запретить
            </v-btn>
            <v-btn depressed text block @click="notifyDialog = false" >
              Не сейчас
            </v-btn>
          </div>
        </v-card-actions>

      </v-card>
    </v-dialog>

  </v-app> 
</template>

<script>

import router from '@/router'
import eventBus from './eventBus';
import socket from '@/services/socket.js'
import store from '@/store'
import NetworkErrorSnackbar from './components/NetworkErrorSnackbar.vue'
import {mapActions, mapGetters} from "vuex";
import Advertising from '@/components/advertising/Advertising';
// import AppVersionDialog from "@/components/AppVersionDialog.vue";
import AppOfferDialog from "@/components/AppOfferDialog.vue";

export default {
  name: 'app',
  components: {
    NetworkErrorSnackbar,
    Advertising,
    AppOfferDialog
  },
  data() {
    return {
      needUpdate: true,
      notifyDialog: false
    }
  },
  router,
  created: function () {
    this.$store.dispatch('loadStatic');

    if (window.platform == 'ios' || window.platform == 'android'){
      if (window.appVersion && window.appVersion != '2.1.6'){
     //   this.needUpdate = true;
      }
    }

    // Запрашиваем права на уведомления
    if (window.platform == 'android'){
      if (window.cordova.plugins.permissions){
        if (!localStorage.getItem('pushLock')){
          var permissions = window.cordova.plugins.permissions;
          // Проверяем разрешены ли push
          permissions.hasPermission(permissions.POST_NOTIFICATIONS, ( status ) => {
            if (!status.hasPermission) {
             //this.notifyDialog = true;
            }
          });
        }
      }
    }

    // Обрабатываем клик по уведомлению Android
    if (window.FirebasePlugin){
      window.FirebasePlugin.onMessageReceived((message) => {
        if (message.payload){
          let jsonPayload = JSON.parse(message.payload)
          if (jsonPayload.url){
            // Если внутренняя ссылка
            if (jsonPayload.url.indexOf('/') === 0){
              this.$router.push(jsonPayload.url)
            } else {
              window.open(jsonPayload.url, '_blank');
            }
          }
        }
      });
    }

    // Вешаем событие на клик по пушу
    // if (window.pushNotification !== undefined){
    //   window.pushNotification.tapped((payload) => {
    //     if (payload){
    //       let jsonPayload = JSON.parse(payload)
    //       if (jsonPayload.url){
    //         // Если внутренняя ссылка
    //         if (jsonPayload.url.indexOf('/') === 0){
    //           this.$router.push(jsonPayload.url)
    //         } else {
    //           window.open(jsonPayload.url, '_blank');
    //         }
    //       }
    //     }
    //   })
    // }

    // Обрабатываем клик по уведомлению IOS
    if (window.FCMPlugin !== undefined){
      window.FCMPlugin.onNotification((data) => {
        if (data && data.wasTapped){
          let jsonPayload = JSON.parse(data.payload);
          if (jsonPayload.url){
            // Если внутренняя ссылка
            if (jsonPayload.url.indexOf('/') === 0){
              this.$router.push(jsonPayload.url)
            } else {
              window.open(jsonPayload.url, '_blank');
            }
          }
        }
      });
    }

  // Прокрутить чат
  eventBus.$on('scrollChat', () => {
    Math.easeInOutQuad = function (t, b, c, d) {
        t /= d/2;
        if (t < 1) return c/2*t*t + b;
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
      };

      let chatDiv = document.querySelector('.chat-messages');
      if (chatDiv){
        let duration = 600;
        var start = chatDiv.scrollTop,
        change = chatDiv.scrollHeight - start,
        currentTime = 0,
        increment = 20;

        var animateScroll = function(){        
            currentTime += increment;
            var val = Math.easeInOutQuad(currentTime, start, change, duration);
            chatDiv.scrollTop = val;
            if(currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();

      }
  });

  // Прочитать свои сообщения
  eventBus.$on('readMessages', () => {
    if (store.getters.currentCouponService){
      store.getters.couponServices.forEach(function(couponService){
        // Если есть непрочитаные сообщения
        if (couponService.unreadCount && couponService.id == store.getters.currentCouponService){
          // Отправляем запрос на прочтение
          let request = {
            event: 'readMessage', 
            couponService: store.getters.currentCouponService,
            token: store.getters.token,
            type: 'customer',
            id: store.getters.activeUser
          };
          socket.socketSend(JSON.stringify(request));
          couponService.unreadCount = 0;
          couponService.messages.forEach(function(message){
            if (!message.fromUser && !message.read){
              message.read = true;
            }
          })
        }
      });
    }
  });

  // Начать звонок врачу
  eventBus.$on('startVideoCall', (idCouponService) => {
    socket.startVideoCall(idCouponService);
  });

  // Открытия чата по услуге
  eventBus.$on('openCouponService', (id) => {
      this.$store.dispatch('setCurrentCouponService', id);
      if (store.getters.noRulesAudio){
          let audio = new Audio(require('@/assets/empty.mp3')); 
          audio.play();
      }
  });

  // Завершить вызов
  eventBus.$on('rejectCall', (idCouponService) => {
    this.$store.dispatch('setCallStatus', 'wait');
    socket.customerRejectCall(idCouponService);
  });
  
  // К списку диалогов в мобильной версии
  eventBus.$on('goToBack', () => {
    if (!this.currentCouponService){
      this.$router.push('/')
    }
    this.$store.dispatch('setCurrentCouponService', null);
  });
  },



  mounted() {
    // try {
      const timestamp = localStorage.getItem('dateTimesAdvertising') || 0;
      if((new Date()).getTime() - timestamp > 60*60*1000){
        this.getAdvertising().then((r) => {
          if(r.data.advertising) {
            localStorage.setItem('dateTimesAdvertising', (new Date()).getTime());
          }
        });
        // localStorage.setItem('dateAdvertising', (new Date()).getTime());
      }
    // } catch (err){
    //   throw err;
    // }
    // if(this.$vuetify.breakpoint.mdAndDown) {

    // }
  },

  computed: {
    ...mapGetters([
      'GET_ADVERTISING'
    ]),
  },
  methods:{

    disallowPush(){
      localStorage.setItem('pushLock', true);
    },

    showPushAlert() {
      var permissions = window.cordova.plugins.permissions;
      permissions.requestPermission(permissions.POST_NOTIFICATIONS, ( status ) =>  {
        this.notifyDialog = false;
        localStorage.setItem('pushLock', true);

        if (!status.hasPermission) {
          if (window.cordova && window.cordova.plugins.settings) {
            window.cordova.plugins.settings.open("application_details", function() {
                  console.log('opened settings');
                },
                function () {
                  console.log('failed to open settings');
                }
            );
          }
          this.notifyDialog = false;
        } else {
          this.notifyDialog = false;
          //alert('Уведомления включены')
        }
      }, (error) =>  {

      });
    },
    ...mapActions([
      'getAdvertising'
    ]),
  }
}
</script>

<style>
:root{
  --primary-color: #82C831;
}
html,
body {
  font-family: 'Roboto', sans-serif !important;
  height: 100%;
}

.v-app-section {
  opacity: 0;
  pointer-events: none;
  transition: opacity .15s ease-in-out;
}

.loaded{
  opacity: 1;
  pointer-events: all;
}

</style>
