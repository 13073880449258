<template>
  <div>
    <div class="header-mobile">
      <v-container>
        <div class="top-toolbar">
          <div class="navigate-btn" >
            <v-btn icon @click="toBack" >
              <v-icon>fas fa-angle-left</v-icon>
            </v-btn>
          </div>
          <div class="page-title">
            {{title}}
          </div>
          <div class="second-btn">
          </div>
        </div>
      </v-container>
    </div>
    <v-container>
      <v-row v-if="!loaded">
        <v-col class="p-0">
          <div>
            <v-skeleton-loader class="mx-0 p-0" type="article"></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="successCreateBanner">
        <v-col>
          <v-alert
              icon="mdi-check"
              prominent
              text
              color="success"
              class="px-5"
          >
            <v-row align="center">
              <v-col class="grow">
                <h5>Запись была успешно создана</h5>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>

      <v-row v-if="loaded">
        <v-col cols=12 class="section-title">
          <h5>Текущие записи к врачу</h5>
        </v-col>
        <v-col cols="12" v-if="isEmpty" class="pt-0">
          <v-alert prominent text>
            На данный момент у вас нет активных записей
          </v-alert>
        </v-col>
      </v-row>
      <v-row >
        <template v-for="appointment in appointments">
          <v-col class="pt-0" cols="12" :key="appointment.id" >
            <v-card outlined>
              <v-list-item three-line>
                <v-list-item-content class="pt-3 pb-2">
                  <div style="width: 100%">
                    <div class="d-flex justify-space-between mb-1">
                      <div>
                        <v-chip class="mb-1 mr-2" color="grey lighten-2" text-color="grey" small>
                          <v-avatar left class="mr-1">
                            <v-icon small>far fa-calendar-alt</v-icon>
                          </v-avatar>Запись на прием
                        </v-chip>
                        <v-chip v-if="appointment.idStatus == 1" class="mb-1 mr-2" color="light-green lighten-1" text-color="white" small >
                        <v-avatar left class="mr-1">
                          <v-icon small>fas fa-check-circle</v-icon>
                        </v-avatar>Оплачено
                      </v-chip>
                      <v-chip v-if="appointment.idStatus === 0 && STATIC.appUseTelemedicine" class="mb-1 mr-2" color="yellow darken-2" text-color="white" small >
                        <v-avatar left class="mr-1">
                          <v-icon small>fas fa-clock</v-icon>
                        </v-avatar>Ожидает оплаты
                      </v-chip>

                        <v-chip  class="mb-1 mr-2" color="light-green lighten-1" text-color="white" small v-if="appointment.statusCode == 1">
                          <v-avatar left class="mr-1">
                            <v-icon small>fas fa-check-circle</v-icon>
                          </v-avatar>Запись подтверждена
                        </v-chip>


                        <v-chip  class="mb-1 mr-2" color="light-green lighten-1" text-color="white" small v-if="appointment.statusCode == 4">
                          <v-avatar left class="mr-1">
                            <v-icon small>fas fa-check-circle</v-icon>
                          </v-avatar>Выполнено
                        </v-chip>

                      </div>
                      <div>
                        <v-btn icon class="px-0" title="Отменить запись" @click="cancelAppointment(appointment.id)" small text color="grey" rounded v-if="getDate() < appointment.dateFrom && (!appointment.idPayment || appointment.idStatus == 0)">
                          <v-icon small>fas fa-times</v-icon>
                        </v-btn>
                      </div>
                    </div>
                    <div>
                      <v-list-item-title class="mb-2">
                        <small>
                          <v-icon class="mr-1" x-small style="margin-top: -2px">far fa-calendar-alt</v-icon>
                          {{ appointmentFormatDate(appointment.dateFrom, appointment.isAdditional) }}
                      
                          <span v-if="appointment.filialAddress">
                            <br><v-icon class="mr-1" x-small style="margin-top: -2px">fas fa-map-marker-alt</v-icon>{{appointment.filialAddress}}
                          </span>

                          <span v-if="!appointment.filialAddress && STATIC.addressActual">
                            <br><v-icon class="mr-1" x-small style="margin-top: -2px">fas fa-map-marker-alt</v-icon>{{STATIC.addressActual}}
                          </span>

                        </small>
                      </v-list-item-title>
            
                    <div class="mb-1">
                      <small><b>Пациент:</b> {{ appointment.customerSurname }} {{ appointment.customerName }} {{ appointment.customerMiddleName }}</small>
                    </div>
                    <div class="mb-1">
                      <small><b>Специалист:</b> {{ appointment.surname }} {{ appointment.name }} {{ appointment.middleName }} </small>
                    </div>


                    <div class="mb-1">
                      <v-btn
                          color="primary"
                          rounded
                          class="mt-2"
                          small
                          depressed
                          v-if="appointment.statusCode == 0 && appointmentCanConfirmByDate(appointment.dateFrom)"
                          @click="confirmAppointment(appointment.id)"
                      >
                        Подтвердить запись
                      </v-btn>
                    </div>

                    <v-alert v-if="appointment.idStatus == 1" @click="openQrCode(appointment.key)" text class="mt-2" style="cursor: pointer">
                      <div style="display: flex;">
                        <div class="mr-3">
                          <Vue-qr-code
                            errorCorrectionLevel="high"
                            margin="0"
                            :value="appointment.key"
                            scale="3"
                          />
                        </div>
                        <div style="display: flex; width: 100%; justify-content: space-between;">
                          <div>
                            <small>
                              
                              <h6 class="mb-1"><b>QR-код для прохода в клинику</b></h6>
                              Для прохода через турникет клиники приложите экран мобильного телефона с QR-кодом талона к считывателю
                            </small>
                          </div>
                          <div>
                            <v-btn icon>
                              <v-icon>fas fa-angle-right</v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </v-alert>

                    <div v-if="appointment.services">
                      <small>
                        <b>Общая стоимость услуг:&nbsp;</b>
                        <span v-if="getServicesAmount(appointment) != getTotalServicesAmout(appointment)">
                          <span>{{getTotalServicesAmout(appointment) / 100}} ₽</span>&nbsp;
                          <span class="discount-old-price mr-1">{{getServicesAmount(appointment) / 100}} ₽</span>&nbsp;
                        </span>
                        <span v-else>
                          {{getTotalServicesAmout(appointment) / 100}} ₽
                        </span>
                      </small>
                    </div>

                    <div class="appointment-services" style="gap: 1rem" v-if="appointment.services && appointment.services.length">
                      <small>
                        <b v-if="appointment.services && appointment.services.length > 1">Услуги:</b>
                        <b v-if="appointment.services && appointment.services.length == 1">Услуга:</b>
                      </small>
                      <v-card outlined class="services-wrap d-flex flex-column mt-3 p-3" style="gap: 1rem">
                        <v-card flat class="service " v-for="(service, index) in appointment.services" :key="service.idService">
                          <div v-if="service.onlineVideo">
                            <v-chip x-small class="mb-1 mr-2" color="grey lighten-2" text-color="grey">
                              <v-avatar left class="mr-1">
                                <v-icon x-small>fas fa-video</v-icon>
                              </v-avatar>Видео с врачом
                            </v-chip>
                          </div>
                          <div v-if="service.onlineChat">
                            <v-chip x-small class="mb-1 mr-2" color="grey lighten-2" text-color="grey">
                              <v-avatar left class="mr-1">
                                <v-icon x-small>fas fa-comment</v-icon>
                              </v-avatar>Чат с врачом
                            </v-chip>
                          </div>
                          <small>
                            <b>Наименование:&nbsp;</b>{{service.nameService}}
                          </small><br>
                          <small v-if="service.serviceTotalAmount && service.serviceAmount">
                            <b>Стоимость:</b> &nbsp;
                            <span v-if="service.serviceAmount == service.serviceTotalAmount">
                              {{service.serviceTotalAmount / 100}} ₽
                            </span>
                            <span v-if="service.serviceAmount != service.serviceTotalAmount">
                              <span>{{service.serviceTotalAmount / 100}} ₽</span>&nbsp;
                              <span class="discount-old-price mr-1">{{service.serviceAmount / 100}} ₽</span>&nbsp;
                              <span v-if="service.discountName">({{service.discountName}})</span>
                            </span>
                          </small>
                          <div class="mt-1" v-if="service.messageToCustomerOnMakingAppointment">
                            <small>
                              <v-icon x-small>fas fa-info-circle</v-icon>
                              <template v-if="stripTags(service.messageToCustomerOnMakingAppointment).length >= 200 && !showFullTextSet.has(`${appointment.id}_${index}`)">
                                <span v-text="sliceTo200Chars(stripTags(service.messageToCustomerOnMakingAppointment))"></span>
                                <span class="font-weight-bold primary--text" style="cursor: pointer" @click="showFull(`${appointment.id}_${index}`)">&nbsp;Показать больше</span>
                              </template>
                              <template v-else>
                                <span v-text="stripTags(service.messageToCustomerOnMakingAppointment)"></span>
                                <span v-if="stripTags(service.messageToCustomerOnMakingAppointment).length >= 200" class="font-weight-bold primary--text" style="cursor: pointer" @click="hideFull(`${appointment.id}_${index}`)">&nbsp;Скрыть</span>
                              </template>
                            </small>
                          </div>
                        </v-card>
                      </v-card>
                    </div>
                    
                  </div>
                </div>
                </v-list-item-content>
                </v-list-item>
                <v-card-actions class="px-0 pb-3" >
                  <v-row class="px-4">
                    <v-col cols="12" lg="3" class="py-0 pb-2">
                      <v-btn
                        block
                        small depressed
                        :loading="paymentLoadingId == appointment.idPayment"
                        dark
                        color="success"
                        v-if="STATIC.useAppAcquiring && appointment.idStatus === 0 && appointment.idPayment"
                        @click="toPay(appointment.idPayment, appointment)"
                      >
                        <v-icon small left>fas fa-credit-card</v-icon>Оплатить
                      </v-btn>
                    </v-col>
    
                    <v-col cols="12" lg="3" class="py-0 pb-2">
                      <v-btn block @click="goToChat" depressed small dark color="primary" v-if="(appointment.onlineVideo || appointment.onlineChat) && appointment.idStatus == 1">Перейти к онлайн-консультации</v-btn>
                    </v-col>
                    
                    <v-col cols="12" lg="3" class="py-0 pb-2">
                      <v-btn block small rounded dark color="light" v-if="appointment.reviewToken && appointment.reviewTokenId" @click="openReviewTab(appointment.reviewToken, appointment.reviewTokenId)">
                        Оценить
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-container>
    
    <PaymentDialog :appointment="paymentDialogAppointment" v-model="showPaymentDialog" @updateAppointmentsData="getAppointments()"/>

    <v-dialog v-model="qrCodeDialog" max-width="400">
      <v-card>
        <v-card-title>QR-код для прохода в клинику</v-card-title>
        <v-card-text>
          <Vue-qr-code
            errorCorrectionLevel="high"
            margin="0"
            :value="this.openedQrCode"
            scale="20"
            style="width: 100%;"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn rounded color="grey" @click="qrCodeDialog = false;" text>Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="cancelAppointmentDialog" persistent max-width="400">
      <v-card>
        <v-card-title>Отмена записи</v-card-title>
        <v-card-text>
          <p>Вы действительно хотите отменить запись?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded color="grey" text @click="cancelAppointmentDialog = false">Нет</v-btn>
          <v-btn rounded color="primary" text :loading="cancelAppointmentLoading" @click="confirmCancelAppointment">Да</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="cancelAppointmentErrorDialog" max-width="400">
      <v-card>
        <v-card-title>Отмена записи</v-card-title>
        <v-card-text>
          <p>Не удалось отменить запись. {{this.cancelAppointmentErrorText}}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded text color="primary" dark @click="cancelAppointmentErrorDialog = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="errorPaymentDialog" max-width="400">
      <v-card>
        <v-card-title>Оплата услуги</v-card-title>
        <v-card-text>
          <p>Не удалось выполнить оплату услуги. {{this.paymentErrorText}}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text rounded color="primary" dark @click="hidePaymentError">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { AppointmentsApiService} from '@/services/api'
import VueQrCode from 'vue-weblineindia-qrcode'
import router from '@/router';
import PaymentDialog from "@/views/Appointments/PaymentDialog";

export default {
    name: 'Appointments',
    components: {
      PaymentDialog,
      VueQrCode,
    },
    data: function() {
      return {
        successCreateBanner: false,
        updateInterval: null,
        appointments: [], // Записи
        loaded: false,
        paymentLoadingId: false,
        title: 'Мои записи',
        cancelAppointmentDialog: false, // Отображение диалога отмены
        cancelAppointmentId: null, // id записи для отмены
        cancelAppointmentLoading: false, // Анимация загрузки удаления записи
        cancelAppointmentErrorText: null,
        cancelAppointmentErrorDialog: false,
        paymentErrorText: '',
        errorPaymentDialog: false,
        qrCodeDialog: false,
        openedQrCode: '',
        showPaymentDialog: false,
        paymentDialogAppointment: null,
        showFullTextSet: new Set()
      }
    },
  
    async mounted() {

      const urlParams = new URLSearchParams(window.location.search);
      const createStatus = urlParams.get('create-status');
      if (createStatus && createStatus == 'success'){
        this.successCreateBanner = true;
      }

      await this.getAppointments()
      this.updateInterval = setInterval(() => {
        this.getAppointments()
      }, 20000)
    },
  
    beforeDestroy(updateInterval) {
      clearInterval(this.updateInterval);
    },
  
    methods: {
        showFull(index) {
          this.showFullTextSet.add(index);
          this.showFullTextSet = new Set(this.showFullTextSet);
        },
      
        hideFull(index) {
          this.showFullTextSet.delete(index);
          this.showFullTextSet = new Set(this.showFullTextSet);
        },
      
        sliceTo200Chars(text) {
          if (text.length < 200) {
            return text;
          }
          
          return text.slice(0, 197) + '...';
        },
      
        stripTags(text) {
            let el = document.createElement('div');
            el.insertAdjacentHTML('beforeend', text);
            return el.innerText;
        },
        openReviewTab(token, id) {
          console.log(token, id);
          router.push(`/get-review?idReview=${id}&token=${token}&from=appointments`);
        },
        // Скрыть ошибку о неудачной оплаты счета
        hidePaymentError(){
          this.errorPaymentDialog = false;
        },
        openQrCode(qrCode){
          this.qrCodeDialog = true;
          this.openedQrCode = qrCode;
        },
        // Перейти к оплате талона
        toPay(idPayment, appointment){
          this.paymentDialogAppointment = appointment;
          this.showPaymentDialog = true;
        },
        // Перейти к чату
        goToChat(){
          this.$router.push('/chat');
        },
        getDate(){
          return this.$moment().format('YYYY-MM-DD HH:mm:ss');
        },
        // Вернутся назад
        toBack: function(){
            this.$router.push('/');
        },
        // Отменить запись
        cancelAppointment(idAppointment){
            this.cancelAppointmentLoading = false;
            this.cancelAppointmentDialog = true;
            this.cancelAppointmentId = idAppointment;
        },
        // Вернуть отформатированную дату записи
        appointmentFormatDate(dateFrom, isAdditional){
            if (isAdditional) {
              return this.$moment(dateFrom).format("LL, dddd");
            }
            return this.$moment(dateFrom).format("LL, dddd, LT");
        },
        appointmentCanConfirmByDate(dateFrom){
          let hours = this.$moment(dateFrom).diff(this.$moment(), 'hours');
          if (hours > 0 && hours <= 44){
            return true;
          }
          return false;
        },
        // Подтверждение удаление записи
        confirmCancelAppointment(){
            this.cancelAppointmentLoading = true;
            this.$http.post(window.config.apiUrl + '/appointment/cancel-appointment', {
                idAppointment: this.cancelAppointmentId,
                idCustomer: this.$store.getters.activeUser
            }).then((response) => {
                if (response.data.status == 'success'){
                    this.cancelAppointmentDialog = false;
                    this.appointments = this.appointments.filter(appointment => appointment.id != this.cancelAppointmentId);
                } else {
                    this.cancelAppointmentDialog = false;
                    this.cancelAppointmentErrorDialog = true;
                    this.cancelAppointmentErrorText = response.data.errorText
                }
            })
            .catch((error) => {
                console.log(error);
                this.cancelAppointmentDialog = false;
                this.cancelAppointmentErrorDialog = true;
                this.cancelAppointmentErrorText = error.statusText;
            });
        },
        // Получить список записей
        async getAppointments() {
            try {
                let appointments = await AppointmentsApiService.getAppointments()
                this.appointments = appointments
            } catch (err) {
                this.appointments = null
            } finally {
                this.loaded = true
            }
        },
        // Получить цену услуг в талоне
        getTotalServicesAmout(appointment) {
          let services = appointment.services ?? [];
          return services.reduce( (prev, val) => prev + val.serviceTotalAmount, 0);
        },
        // Получить цену услуг до применения скидок
        getServicesAmount(appointment) {
          let services = appointment.services ?? [];
          return services.reduce( (prev, val) => prev + val.serviceAmount, 0);
        },
        confirmAppointment(id){
            this.$http.post(window.config.apiUrl + '/appointment/confirm-appointment', {
              idAppointment: id,
            }).then((response) => {
              if (response.data.status == 'success'){
                this.getAppointments()
              }
              this.getAppointments()
            })
        },
      
        // Применяет промокод
        applyPromocode() {
        
        }
    },
  
    computed: {
        ...mapGetters([
          'STATIC'
        ]),
        isEmpty() {
            if (this.appointments){
              return this.appointments.length == 0 ? true : false
            } else {
              return true;
            }
        }
    }
}

</script>

<style scoped>

.main-container{
  height: calc(100vh - 300px);
}

.discount-old-price{
  color: #bbb;
  text-decoration: line-through;
  font-style: italic;
}

.custom-expansion-panel {
  border-bottom: 1px solid var(--exp-color);
}

@media screen and  (max-width: 1200px){

  .main-container{
    height: calc(100vh - 200px);
  }

}

</style>